import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef
} from 'react';

import { Col } from 'react-bootstrap';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  companyFormValidation,
  freelanceFormValidation,
  particularFormValidation
} from 'app/modules/MyAccount/pages/Billing/utils';
import { CUSTOMER_TYPES } from 'app/utils/constants';
import { filterObjectKeys } from 'app/utils/objects/filterObjectKeys';
import { genericRequest } from 'app/api';
import { translateCountryToLocale } from 'app/utils/parsers/translateCountryToLocale';
import { URLS } from 'app/config/urls';
import BillForm from 'app/modules/MyAccount/pages/Billing/components/BillForm';
import Button from 'app/components/Button';
import FormCard from 'app/components/FormCard';
import FormSpinner from 'app/components/FormSpinner';
import MyAccountLayout from 'app/modules/MyAccount/components/MyAccountLayout';

import 'app/modules/MyAccount/pages/Billing/Billing.scss';

export default function Billing() {
  const intl = useIntl();
  const { user } = useSelector((state) => state.auth);
  const customerType = useMemo(
    () => user.attributes.customerType,
    [user.attributes.customerType]
  );

  const [formValidations, setFormValidations] = useState();
  const [initialValues, setInitialValues] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [addressId, setSendAddressId] = useState(0);
  const container = useRef(null);

  useEffect(() => {
    switch (customerType) {
      case CUSTOMER_TYPES.particular.id:
        setFormValidations(particularFormValidation);
        break;
      case CUSTOMER_TYPES.company.id:
        setFormValidations(companyFormValidation);
        break;
      case CUSTOMER_TYPES.freelancer.id:
        setFormValidations(freelanceFormValidation);
        break;

      default:
        break;
    }

    return () => setFormValidations();
  }, [customerType]);

  const getData = useCallback(async () => {
    setIsLoading(true);

    const {
      data: { attributes: form, id }
    } = await genericRequest({
      url: 'customers/me/invoice-address'
    });

    const web = form?.url || '';
    const comercialName = form?.companyName || '';

    const countryName = translateCountryToLocale({
      lang: intl.locale.toUpperCase(),
      country: form?.country
    });

    const formContent = {
      url: web,
      comercialName,
      ...form,
      country: countryName
    };

    setInitialValues(formContent);
    setSendAddressId(id);
    setIsLoading(false);

    return formContent;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormSubmission = async (values, actions) => {
    setIsLoading(true);

    const mapKeys = {
      // campos a enviar
      1: ['address', 'phone', 'phonePrefix'], // particular
      2: ['address', 'phone', 'url', 'phonePrefix'], // autónomo
      3: ['address', 'phone', 'url', 'contactName'] // empresa
    };

    const requestBody = filterObjectKeys({
      values,
      keys: mapKeys[customerType]
    });

    await genericRequest({
      url: 'customers/me/invoice-address',
      method: 'patch',
      data: {
        data: {
          type: 'invoice-address',
          id: addressId,
          attributes: { ...requestBody }
        }
      },
      customSuccessFeedback: {
        variant: 'success',
        content: intl.formatMessage({
          id: 'controlpanel.feedback.save_changes.success'
        }),
        autoClose: true,
        dismissible: false
      }
    });

    const formContent = await getData();
    actions.resetForm({ values: formContent });
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
    return () => {
      setInitialValues(false);
      setSendAddressId(0);
      setIsLoading(false);
    };
  }, [getData]);

  if (isLoading) {
    return (
      <MyAccountLayout currentPagePath={URLS.myAccountBilling}>
        <Col md={8}>
          <FormSpinner
            title="controlpanel.my_account_profile_card.billing"
            showButtons
          />
        </Col>
      </MyAccountLayout>
    );
  }

  return (
    <MyAccountLayout currentPagePath={URLS.myAccountBilling}>
      <Col md={8}>
        {initialValues && (
          <Formik
            initialValues={initialValues}
            validationSchema={formValidations}
            onSubmit={handleFormSubmission}
          >
            {(formik) => (
              <div className="bb-billing-page" ref={container}>
                <FormCard
                  title={intl.formatMessage({
                    id: 'controlpanel.my_account_profile_card.billing'
                  })}
                  buttons={
                    <>
                      <Button
                        size="big"
                        text={intl.formatMessage({
                          id: 'controlpanel.global.cancel'
                        })}
                        onClick={() => formik.handleReset()}
                      />
                      <Button
                        size="big"
                        type="secondary"
                        text={intl.formatMessage({
                          id: 'controlpanel.global.save'
                        })}
                        typeButton="submit"
                        onClick={() => formik.submitForm()}
                        disabled={!(formik.isValid && formik.dirty)}
                      />
                    </>
                  }
                >
                  {customerType && <BillForm customerType={customerType} />}
                </FormCard>
              </div>
            )}
          </Formik>
        )}
      </Col>
    </MyAccountLayout>
  );
}
