export const REGEX_PHONE = /^[0-9]*$/;
export const REGEX_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const REGEX_PASSWORD =
  /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
export const REGEX_PREFIX = /^(\+[0-9]{1,3})$/;
export const REGEX_NIE = /^[XYZ]\d{7,8}[A-Z]$/;
export const REGEX_NIF = /^(\d{8})([A-Z])$/;
export const REGEX_CIF = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
export const REGEX_URL =
  '^(https?:\\/\\/)?([\\da-z\\.-]+)\\.([a-z\\.]{2,6})([\\/\\w \\?=.-]*)*\\/?$';
export const REGEX_ADDRESS = /^([^!<>?=+@{}_$"%]+)$/;
